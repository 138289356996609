import { onlyNumber, removeCommas } from '@common/module/replaceNumber';
import BusinessHomeModel from '@model/BusinessHomeModel';
import BusinessHomeService from '@service/BusinessHomeService';
import { observable, toJS } from 'mobx';

class BookKeepingFeeChangeModalVm {
  constructor() {
    this.data = observable({
      bookkeepingServiceFee: toJS(BusinessHomeModel.business.customer.bookkeepingService.bookkeepingServiceFee),
      alimtalkToList: toJS(BusinessHomeModel.business.customer.alimtalkToList.BOOKKEEPING_FEE_CHANGE_REQUEST),
      needNewBookkeepingServiceContract: false,
      needNewCMSContract: false,
    });
  }
  bookkeepingServiceFee(e) {
    this.data.bookkeepingServiceFee = onlyNumber(removeCommas(e.target.value));
  }
  changeAlimtalkToList(e, index) {
    this.data.alimtalkToList[index].checkedYn = e.target.checked;
  }
  changeCheckbox(e, key) {
    this.data[key] = e.target.checked;
  }
  saveActive(type) {
    if (!this.data.bookkeepingServiceFee) {
      return false;
    }
    if (type === 'alimtalk') {
      return this.data.alimtalkToList.some((person) => {
        //알림톡 받으실분
        return person.checkedYn === true;
      });
    }

    return true;
  }
  request(businessId, type) {
    const param = {
      newFee: this.data.bookkeepingServiceFee,
      needNewBookkeepingServiceContract: this.data.needNewBookkeepingServiceContract,
      needNewCMSContract: this.data.needNewCMSContract,
    };

    if (type === 'alimtalk') {
      param.userIds = this.data.alimtalkToList
        .filter((person) => {
          return person.checkedYn === true;
        })
        .map((checkedPerson) => {
          return checkedPerson.id;
        });
    }

    BusinessHomeService.changeBookkeepingServiceFee(businessId, param, type);
  }
}

export default BookKeepingFeeChangeModalVm;
