import { useObserver } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import vm from './ChangeEmailVm';
import st from './ChangeEmail.module.scss';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import queryString from 'query-string';
import regExp from '@common/module/regExp';
import partner from '@common/module/partner';

function ChangeEmail() {
  let location = useLocation();
  const token = queryString.parse(location.search).token;

  return useObserver(() => (
    <div className={st.changeEmail}>
      <form className={st.changeEmailForm} onSubmit={(e) => vm.submit(e, token)}>
        <div className={st.logo}>
          {partner.type === partner.바른 && <img src="/images/loginLogo_barun.svg?v=1" alt="바른 로고" />}
          {partner.type === partner.진평 && <img src="/images/loginLogo_jp.svg" alt="진평 로고" />}
        </div>
        <div className={st.explain} style={{ marginBottom: '56px' }}>
          <div className={st.title}>변경하실 이메일 주소를 입력하세요.</div>
        </div>
        <div>
          <div className={st.label}>
            <label>변경할 이메일 주소</label>
          </div>
          <InputText
            fullSize
            value={vm.state.email}
            onChange={(e) => vm.changeEmail(e)}
            name="email"
            errorText={
              <Error
                name="email"
                value={vm.state.email}
                errorCase={{
                  required: '이메일 주소를 입력하세요.',
                  pattern: {
                    value: regExp.email().test(vm.state.email),
                    message: '올바른 이메일 형식으로 입력하세요.',
                  },
                }}
              />
            }
          />
        </div>
        <div className={st.btnArea}>
          <SquareBtn className={st.findBtn} type="submit" color="white">
            <span>이메일 재설정하기</span>
          </SquareBtn>
        </div>
      </form>
    </div>
  ));
}
export default ChangeEmail;
